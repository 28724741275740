import { useCallback, useEffect, useMemo, useState } from 'react';

import { useSwapAvailablity } from '@app/hooks/swap/useSwapAvailablity';
import { useTokensRate, useTokensRateV2 } from '@app/hooks/swap/useTokensRate';
import { useTrade } from '@app/hooks/swap/useTrade';
import { Token } from '@app/types/token';
import { USTrade } from '@app/types/trade';
import fromExponential from 'from-exponential';
import { useSwapActionHandlers } from '@app/state/swapStore';
import { SwapField } from '@app/types/swap-field';
import { getCurrencyFromKimToken } from '@app/helpers/currency';
import { useChainId } from '@thirdweb-dev/react';

export function useSwapTokens(initialTokensData: { from: Token; to: Token }) {
  const [state, setState] = useState<{
    from: {
      token: Token;
      amount: string;
    };
    to: {
      token: Token;
      amount: string;
    };
    selectedTrade: USTrade | undefined;
  }>({
    from: {
      token: initialTokensData.from,
      amount: ''
    },
    to: {
      token: initialTokensData.to,
      amount: ''
    },
    selectedTrade: undefined
  });

  const { trades, refetch: refetchTrades } = useTrade(state.from, state.to);

  const resetStateValues = useCallback(() => {
    setState(prev => ({
      ...prev,
      from: {
        ...prev.from,
        amount: ''
      },
      to: {
        ...prev.to,
        amount: ''
      }
    }));

    refetchTrades();
  }, [refetchTrades]);

  const handleFromTokenChange = useCallback((val: string) => {
    setState(prev => ({
      ...prev,
      from: {
        ...prev.from,
        amount: fromExponential(val)
      }
    }));
  }, []);

  const handleFromTokenSelect = useCallback((token: Token) => {
    setState(prev => ({
      ...prev,
      from: {
        ...prev.from,
        token
      },
      to:
        token.contractAddress.toLowerCase() ===
        prev.to.token.contractAddress.toLowerCase()
          ? { ...prev.from }
          : { ...prev.to }
    }));
  }, []);

  const handleToTokenSelect = useCallback((token: Token) => {
    setState(prev => ({
      ...prev,
      to: {
        ...prev.to,
        token
      },
      from:
        token.contractAddress.toLowerCase() ===
        prev.from.token.contractAddress.toLowerCase()
          ? { ...prev.to, amount: prev.from.amount }
          : { ...prev.from }
    }));
  }, []);

  const handleFlip = useCallback(() => {
    setState(prev => ({
      ...prev,
      from: { ...prev.to, amount: prev.from.amount },
      to: { ...prev.from, amount: '' }
    }));
  }, []);

  const setSelectedTrade = useCallback((val: USTrade | undefined) => {
    setState(prev => ({
      ...prev,
      selectedTrade: val
    }));
  }, []);

  const {
    isSwapAvailable,
    isLoading: isSwapAvailabilityLoading,
    pairAddress
  } = useSwapAvailablity(state.from.token, state.to.token, trades);

  const {
    data,
    isLoading,
    refetch: fetchRates
  } = useTokensRateV2(
    {
      from: state.from.token,
      to: state.to.token,
      amount: state.from.amount,
      isSwapAvailable,
      trades,
      selectedTrade: state.selectedTrade
    },
    {
      refetchInterval: 10000
    }
  );
  const { swapOut, rate } = data ?? {};

  return {
    trades,
    state,
    setState,
    handleFromTokenChange,
    handleFromTokenSelect,
    handleToTokenSelect,
    handleFlip,
    swapOut,
    isLoadingSwapOut: isLoading,
    rate,
    isSwapAvailable,
    isSwapAvailabilityLoading,
    fetchRates,
    resetStateValues,
    pairAddress,
    setSelectedTrade
  };
}

export function useInitialSwapSetup(
  initialTokensData:
    | {
        from: Token;
        to: Token;
      }
    | undefined
) {
  const chainId = useChainId();
  const { onCurrencySelection } = useSwapActionHandlers();

  useEffect(() => {
    if (!initialTokensData || !chainId) {
      return;
    }

    const currency0 = getCurrencyFromKimToken(initialTokensData.from, chainId);
    const currency1 = getCurrencyFromKimToken(initialTokensData.to, chainId);

    if (!currency0 || !currency1) {
      return;
    }

    onCurrencySelection(SwapField.INPUT, currency0);
    onCurrencySelection(SwapField.OUTPUT, currency1);
  }, [initialTokensData, onCurrencySelection, chainId]);
}
