import { Box, ChakraProps, VStack } from '@chakra-ui/react';
import React, { FC, useState } from 'react';

import { TransactionResultModal } from '@app/components/TransactionResultModal';
import { Token } from '@app/types/token';
import { SwapPair } from '@app/components/SwapV3/components/SwapPair';
import { SwapParams } from '@app/components/SwapV3/components/SwapParams/SwapParams';
import { SwapButton } from '@app/components/SwapV3/components/SwapButton';
import { HeaderControlsV3 } from '@app/components/Swap/components/HeaderControls/HeaderControlsV3';
import { useInitialSwapSetup } from '@app/components/SwapV3/hooks';

interface Props extends ChakraProps {
  initialTokensData: {
    from: Token;
    to: Token;
  };
}

export const SwapV3: FC<Props> = ({ initialTokensData, ...rest }) => {
  const [feedback, setFeedback] = useState<{
    status: 'success' | 'error';
    title: string;
    message: string;
    txHash?: string;
  } | null>(null);

  useInitialSwapSetup(initialTokensData);

  return (
    <Box
      padding="12px"
      bg="gray.2"
      borderRadius={16}
      maxWidth="573px"
      mx="auto"
      w="100%"
      {...rest}
    >
      <VStack>
        <HeaderControlsV3 />
        <SwapPair />
        <SwapButton />
        <SwapParams />
        <TransactionResultModal
          isOpen={!!feedback}
          status={feedback?.status}
          title={feedback?.title}
          message={feedback?.message}
          txHash={feedback?.txHash}
          onClose={() => setFeedback(null)}
        />
      </VStack>
    </Box>
  );
};
